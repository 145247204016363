var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { ref: "floors", staticClass: "floors-wrapper" },
    [
      _vm._l(_vm.listFloor, function (floor, floorIndex) {
        return _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value:
                  _vm.currentFloor === floor.floor || _vm.currentFloor === 0,
                expression: "currentFloor===floor.floor || currentFloor === 0",
              },
            ],
            key: floorIndex,
            staticClass: "floor",
          },
          [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: floor.tipValue,
                    expression: "floor.tipValue",
                  },
                ],
                ref: "tooltip_" + floorIndex,
                refInFor: true,
                staticClass: "floortip el-tooltip__popper is-dark",
                staticStyle: {
                  "transform-origin": "center bottom",
                  "z-index": "5180",
                },
              },
              [
                _c(
                  "div",
                  { staticClass: "content" },
                  [
                    _c("p", [_vm._v(_vm._s(_vm.selectedRoom.name))]),
                    _c("p", [
                      _vm._v(_vm._s(_vm.selectedRoom.buildingArea) + "m²"),
                    ]),
                    _vm.viewType === 0
                      ? [
                          _vm.selectedRoom.listOrg &&
                          _vm.selectedRoom.listOrg.length > 0
                            ? _c(
                                "div",
                                _vm._l(
                                  _vm.selectedRoom.listOrg,
                                  function (org, key) {
                                    return _c("p", { key: key }, [
                                      _vm._v(
                                        "\n            " +
                                          _vm._s(org) +
                                          "\n          "
                                      ),
                                    ])
                                  }
                                ),
                                0
                              )
                            : _vm._e(),
                          _vm.selectedRoom.listOrg &&
                          _vm.selectedRoom.listOrg.length === 0
                            ? _c("p", [_vm._v("未入驻")])
                            : _vm._e(),
                        ]
                      : _vm._e(),
                    _vm.viewType === 1
                      ? [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.isSellMap[_vm.selectedRoom.isSell]) +
                              "\n        "
                          ),
                        ]
                      : _vm._e(),
                  ],
                  2
                ),
                _c("div", {
                  staticClass: "tip_arrow popper__arrow",
                  staticStyle: { left: "28px" },
                  attrs: { "x-arrow": "" },
                }),
              ]
            ),
            _c("div", { staticClass: "floor-header" }, [
              _c("div", { staticClass: "floor-header-name" }, [
                _vm._v(" " + _vm._s(floor.floor) + "层"),
              ]),
              _c(
                "div",
                { staticClass: "floor-header-status" },
                [
                  _vm.viewType === 0
                    ? [
                        _c("div", { staticClass: "floor-header-status-info" }, [
                          _c("span", [_vm._v("待租")]),
                          _c("span", [
                            _vm._v(_vm._s(floor.forLeaseArea) + "m²"),
                          ]),
                        ]),
                      ]
                    : _vm._e(),
                  _vm.viewType === 1
                    ? [
                        _c("div", { staticClass: "floor-header-status-info" }, [
                          _c("span", [_vm._v("待售")]),
                          _c("span", [
                            _vm._v(_vm._s(floor.forSellArea) + "m²"),
                          ]),
                        ]),
                      ]
                    : _vm._e(),
                ],
                2
              ),
            ]),
            _c(
              "ul",
              { staticClass: "floor-content" },
              _vm._l(floor.roomList, function (item, index) {
                return _c(
                  "li",
                  {
                    key: index,
                    class: ["floor-content-house", _vm.getClass(item)],
                    style: { width: item.percent },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.clickRoom(item)
                      },
                    },
                  },
                  [
                    _c("div", { staticClass: "floor-content-house-info" }, [
                      _c(
                        "div",
                        [
                          item.rate >= _vm.standard
                            ? [
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "floor-content-house-info-name",
                                  },
                                  [_vm._v(_vm._s(item.room))]
                                ),
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "floor-content-house-info-area",
                                  },
                                  [_vm._v(_vm._s(item.buildingArea) + "m²")]
                                ),
                              ]
                            : _vm._e(),
                          item.rate < _vm.standard && item.rate > 0
                            ? [_vm._v("\n                ...\n              ")]
                            : _vm._e(),
                        ],
                        2
                      ),
                    ]),
                    _c(
                      "div",
                      {
                        ref: "test",
                        refInFor: true,
                        staticClass: "floor-content-house-group",
                        on: {
                          mouseenter: function ($event) {
                            return _vm.mouseenter(item, index, floorIndex)
                          },
                          mouseleave: function ($event) {
                            return _vm.mouseleave(item, index, floorIndex)
                          },
                        },
                      },
                      [
                        item.listOrg.length > 0
                          ? _c(
                              "div",
                              {
                                staticStyle: {
                                  width: "100%",
                                  "text-align": "center",
                                },
                              },
                              [
                                item.rate >= _vm.standard
                                  ? [
                                      _vm.viewType === 0
                                        ? [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "floor-content-house-group-title",
                                                style: {
                                                  "justify-content":
                                                    item.listOrg.length < 5
                                                      ? "center"
                                                      : "flex-start",
                                                },
                                              },
                                              _vm._l(
                                                item.listOrg,
                                                function (org, orgIndex) {
                                                  return _c(
                                                    "p",
                                                    { key: orgIndex },
                                                    [
                                                      _vm._v(
                                                        "\n                      " +
                                                          _vm._s(org) +
                                                          "\n                    "
                                                      ),
                                                    ]
                                                  )
                                                }
                                              ),
                                              0
                                            ),
                                          ]
                                        : _vm._e(),
                                      _vm.viewType === 1
                                        ? [
                                            _vm._v(
                                              "\n                   " +
                                                _vm._s(
                                                  _vm.isSellMap[item.isSell]
                                                ) +
                                                "\n                "
                                            ),
                                          ]
                                        : _vm._e(),
                                    ]
                                  : _vm._e(),
                                item.rate < _vm.standard
                                  ? [
                                      _vm._v(
                                        "\n                ...\n              "
                                      ),
                                    ]
                                  : _vm._e(),
                              ],
                              2
                            )
                          : _vm._e(),
                        item.listOrg.length === 0
                          ? _c(
                              "div",
                              { staticClass: "floor-content-house-group" },
                              [
                                item.rate >= _vm.standard
                                  ? [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(
                                            _vm.viewType === 0
                                              ? "未入驻"
                                              : _vm.isSellMap[item.isSell]
                                          ) +
                                          "\n              "
                                      ),
                                    ]
                                  : _vm._e(),
                                item.rate < _vm.standard
                                  ? [
                                      _vm._v(
                                        "\n                ...\n              "
                                      ),
                                    ]
                                  : _vm._e(),
                              ],
                              2
                            )
                          : _vm._e(),
                        _c("div", { class: { isCover: item.isCover } }),
                      ]
                    ),
                  ]
                )
              }),
              0
            ),
          ]
        )
      }),
      _c("transition", { attrs: { name: "fade" } }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.dialogVisible,
                expression: "dialogVisible",
              },
            ],
            ref: "orgInfo",
            staticClass: "my-room-info",
          },
          [
            _c(
              "div",
              { staticClass: "room-info" },
              [
                _c(
                  "span",
                  {
                    staticClass: "goback",
                    on: {
                      click: function ($event) {
                        _vm.dialogVisible = false
                      },
                    },
                  },
                  [
                    _c("i", { staticClass: "el-icon-arrow-left" }),
                    _vm._v("返回"),
                  ]
                ),
                _c("div", { staticClass: "base-info" }, [
                  _c(
                    "div",
                    { staticClass: "base-info-header" },
                    [
                      _c("div", [
                        _c("span", {
                          staticClass: "pre-point",
                          staticStyle: { "background-color": "#7AC943" },
                        }),
                        _c("span", [_vm._v("房屋信息")]),
                      ]),
                      _c(
                        "v-button",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.editHousePermission,
                              expression: "editHousePermission",
                            },
                          ],
                          attrs: { type: "text" },
                          on: {
                            click: function ($event) {
                              return _vm.editHouse(_vm.currentRoom)
                            },
                          },
                        },
                        [_vm._v("编辑")]
                      ),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "base-info-name" }, [
                    _c("span", { staticClass: "flod" }, [
                      _vm._v(_vm._s(_vm.currentRoom.name)),
                    ]),
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.viewType === 0
                            ? _vm.isLeasesMap[_vm.currentRoom.isLease]
                            : _vm.isSellMap[_vm.currentRoom.isSell]
                        )
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "base-info-name" }, [
                    _c("span", [
                      _vm._v(
                        "面积：" + _vm._s(`${_vm.currentRoom.buildingArea}m²`)
                      ),
                    ]),
                  ]),
                ]),
                _vm._l(_vm.orgInfos, function (orgInfo, orgIndex) {
                  return _c(
                    "div",
                    { key: orgIndex, staticClass: "org-info" },
                    [
                      _c("div", { staticClass: "org-info-base" }, [
                        _c("div", { staticClass: "org-info-base-header" }, [
                          _c("span", [_vm._v("企业信息")]),
                          _c(
                            "div",
                            [
                              _vm.orgMovePermission
                                ? _c(
                                    "v-button",
                                    {
                                      staticClass: "redBtn",
                                      attrs: { type: "text" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.orgMove(orgInfo.orgId)
                                        },
                                      },
                                    },
                                    [_vm._v("搬离")]
                                  )
                                : _vm._e(),
                              _vm.orgMgtPermission
                                ? _c(
                                    "v-button",
                                    {
                                      attrs: { type: "text" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.orgMgt(orgInfo.orgId)
                                        },
                                      },
                                    },
                                    [_vm._v("编辑")]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]),
                        _c("div", { staticClass: "org-info-base-name flod" }, [
                          _vm._v(_vm._s(orgInfo.orgName)),
                        ]),
                        orgInfo.contactName
                          ? _c("div", [
                              _vm._v(
                                "\n                    联系人：" +
                                  _vm._s(`${orgInfo.contactName}`) +
                                  "\n                "
                              ),
                            ])
                          : _vm._e(),
                      ]),
                      orgInfo.contractInfos.length > 0
                        ? _vm._l(
                            orgInfo.contractInfos,
                            function (contactInfo, contactIndex) {
                              return _c(
                                "div",
                                {
                                  key: contactIndex,
                                  staticClass: "org-info-contact",
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "org-info-contact-header" },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(contactInfo.contractName)
                                        ),
                                      ]),
                                      _c(
                                        "div",
                                        { staticStyle: { flex: "0 0 138px" } },
                                        [
                                          _c(
                                            "v-button",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    _vm.contactPermission &&
                                                    _vm.contactPermission
                                                      .invalid,
                                                  expression:
                                                    "contactPermission && contactPermission.invalid",
                                                },
                                              ],
                                              staticClass: "redBtn",
                                              attrs: { type: "text" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.voidContact(
                                                    contactInfo.contractId
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("作废")]
                                          ),
                                          _c(
                                            "v-button",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    _vm.contactPermission &&
                                                    _vm.contactPermission.renew,
                                                  expression:
                                                    "contactPermission && contactPermission.renew",
                                                },
                                              ],
                                              attrs: { type: "text" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.renewContact(
                                                    contactInfo.contractId
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("续租")]
                                          ),
                                          _c(
                                            "v-button",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    _vm.contactPermission &&
                                                    _vm.contactPermission.view,
                                                  expression:
                                                    "contactPermission && contactPermission.view",
                                                },
                                              ],
                                              attrs: { type: "text" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.viewContact(
                                                    contactInfo.contractId
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("查看")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "org-info-contact-time" },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "org-info-contact-time-left",
                                        },
                                        [
                                          _c("span", { staticClass: "flod" }, [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  `${contactInfo.startDate}起${contactInfo.endDate}止`
                                                )
                                            ),
                                          ]),
                                          contactInfo.fee
                                            ? _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    `费用: ${contactInfo.fee}`
                                                  )
                                                ),
                                              ])
                                            : _vm._e(),
                                        ]
                                      ),
                                      contactInfo.remainDays < 180
                                        ? _c("div", {
                                            staticClass:
                                              "org-info-contact-expired",
                                          })
                                        : _vm._e(),
                                    ]
                                  ),
                                  contactInfo.arrearage && _vm.chargePermission
                                    ? _c(
                                        "v-button",
                                        {
                                          staticStyle: {
                                            "padding-left": "0px",
                                          },
                                          attrs: { type: "text" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.chargeBillMgt(
                                                orgInfo.orgId
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("查看欠费账单")]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              )
                            }
                          )
                        : _vm._e(),
                      orgInfo.contractInfos.length === 0 &&
                      _vm.contactPermission &&
                      _vm.contactPermission.add === 1
                        ? [
                            _c("div", { staticClass: "org-info-contact" }, [
                              _c(
                                "div",
                                { staticClass: "org-info-contact-header" },
                                [
                                  _c("span", [_vm._v("合同信息")]),
                                  _c(
                                    "div",
                                    [
                                      _c(
                                        "v-button",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value:
                                                _vm.contactPermission &&
                                                _vm.contactPermission.add === 1,
                                              expression:
                                                "contactPermission && contactPermission.add === 1",
                                            },
                                          ],
                                          attrs: { type: "text" },
                                          on: { click: _vm.createContact },
                                        },
                                        [_vm._v("创建合同")]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "org-info-contact-nodata" },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: _vm.noContactImg,
                                      width: "17",
                                    },
                                  }),
                                  _c(
                                    "span",
                                    { staticStyle: { "padding-top": "6px" } },
                                    [_vm._v("暂无合同信息")]
                                  ),
                                ]
                              ),
                            ]),
                          ]
                        : _vm._e(),
                    ],
                    2
                  )
                }),
                _vm.orgInfos.length === 0 &&
                _vm.contactPermission &&
                _vm.contactPermission.add === 1
                  ? _c("div", { staticClass: "org-info" }, [
                      _c("div", { staticClass: "org-info-contact noOrg" }, [
                        _c("div", { staticClass: "org-info-contact-header" }, [
                          _c("span", [_vm._v("合同信息")]),
                          _c(
                            "div",
                            [
                              _c(
                                "v-button",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value:
                                        _vm.contactPermission &&
                                        _vm.contactPermission.add === 1,
                                      expression:
                                        "contactPermission && contactPermission.add === 1",
                                    },
                                  ],
                                  attrs: { type: "text" },
                                  on: { click: _vm.createContact },
                                },
                                [_vm._v("创建合同")]
                              ),
                            ],
                            1
                          ),
                        ]),
                        _c("div", { staticClass: "org-info-contact-nodata" }, [
                          _c("img", {
                            attrs: { src: _vm.noContactImg, width: "17" },
                          }),
                          _c(
                            "span",
                            { staticStyle: { "padding-top": "6px" } },
                            [_vm._v("暂无合同信息")]
                          ),
                        ]),
                      ]),
                    ])
                  : _vm._e(),
              ],
              2
            ),
          ]
        ),
      ]),
      _vm.listFloor.length === 0
        ? [
            _c("div", { staticClass: "empty-wrapper" }, [
              _c("img", { attrs: { src: _vm.emptyImgSrc } }),
              _c("p", [_vm._v("请先录入房号…")]),
            ]),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }