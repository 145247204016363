<!--
 * @Author: xfj
 * @Date: 2019-09-17 13:40:31
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-08-19 16:49:37
 * @Description: 项目中心-项目房号-资产剖面图
 -->
<template>
  <div class="houseProfileList-wrapper" ref="houseProfile">
    <!-- <w-room-tree
      ref="roomTree" 
      :lazy="true"
      :style="{zIndex:3000}"
      @loadData="treeLoad"
      :defaultProps="treeProps"
      :visible.sync="openDrawer"
      @current-change="selectNode"
      :defaultExpandedKeys="defaultExpandedKeys"
      :treeData="treeData"
    ></w-room-tree> -->
    <custom-view>
      <div class="header">
        <v-button text="编辑" v-show="showHouseBtn" @click="edit"></v-button>
        <v-button text="查看附件" v-show="showHouseBtn" @click="checkFiles"></v-button>
        <v-button text="新增房号" v-show="addHousePermission" @click="create"></v-button>
        <v-button text="批量导入" v-show="importHousePermission" @click="goPageImport"></v-button>
      </div>
      <div class="content-wrapper">
        <!-- <el-scrollbar v-show="treeShow">
          <div class="tree-group">
            <v-tree
              :data="treeData"
              :defaultProps="treeProps"
              :lazy="true"
              :expand-on-click-node="false"
              :highlightCurrent="true"
              @loadData="treeLoad"
              @nodeClick="selectNode"
            ></v-tree>
          </div>
        </el-scrollbar>
        <div class="treeSwith" @click="treeSwith">
          <img v-if="treeShow" :src="treeOffImgSrc" />
          <img v-if="!treeShow" :src="treeOnImgSrc" />
        </div> -->

        <div class="right-content">
          <template v-if="hasInfo">
            <div class="viewtype-wrapper">
              <span>{{ blockBuildingName }}</span>
            </div>
            <div class="building-info" v-loading="isSearching">
              <!-- 新增楼宇基础信息 -->
              <div class="build-base-info">
                <!-- <div class="base-title">楼宇基础信息：</div> -->
                <ul class="area">
                  <li class="blue">
                    <span>总户数：</span>
                    <span>{{ this.totalHouseCount }}户</span>
                  </li>
                  <li class="orange">
                    <span>楼层数：</span>
                    <span>{{ buildingBaseInfo.buildingNum }}层</span>
                  </li>
                  <li class="green">
                    <span>建筑面积：</span>
                    <span>{{ this.totalArea }}m&sup2;</span>
                  </li>
                </ul>
                <div class="base-desc">
                  <div class="desc-item">
                    <div>所属项目：{{communityName}}</div>
                    <div>建筑类型：{{architectureTypeMap[buildingBaseInfo.architectureType]}}</div>
                    <div>&nbsp;&nbsp;&nbsp;&nbsp;梯户比：{{ladderThanFamilyTypeMap[buildingBaseInfo.ladderThanFamily]}}</div>
                  </div>
                  <div class="desc-item">
                    <div>楼宇名称：{{ blockBuildingName }}</div>
                    <div>楼宇类型：{{buildingTypeMap[buildingBaseInfo.buildingType]}}</div>
                    <div>交付情况：{{deliverySituationTypeMap[buildingBaseInfo.deliverySituation]}}</div>
                  </div>
                  <div class="desc-item">
                    <div>楼宇编号：{{buildingBaseInfo.buildingSort}}</div>
                    <div>楼宇状态：{{buildingStatusMap[buildingBaseInfo.buildingStatus]}}</div>
                    <div>交付时间：{{buildingBaseInfo.deliveryTime}}</div>
                  </div>
                </div>
              </div>
              <div class="viewtype-wrapper">
                <!-- <span>{{ blockBuildingName }}</span> -->
                <div class="swichView" @click="swichView">
                  <span class="name">
                    {{
                    viewType === 0 ? "租赁视图" : "销售视图"
                    }}
                  </span>
                  <span class="swich">
                    切换
                    <i class="el-icon-caret-right"></i>
                  </span>
                </div>
              </div>
              <ul class="area">
                <li class="blue">
                  <span>总面积：</span>
                  <span>{{ buildingInfo.totalArea }}m&sup2;</span>
                </li>
                <li class="orange" v-show="viewType === 0">
                  <span>已租面积：</span>
                  <span>{{ buildingInfo.leaseArea }}m&sup2;</span>
                </li>
                <li class="green" v-show="viewType === 0">
                  <span>待租面积：</span>
                  <span>{{ buildingInfo.forLeaseArea }}m&sup2;</span>
                </li>
                <li class="purple" v-show="viewType === 1">
                  <span>已售面积：</span>
                  <span>{{ buildingInfo.sellArea }}m&sup2;</span>
                </li>
                <li class="orange" v-show="viewType === 1">
                  <span>待售面积：</span>
                  <span>{{ buildingInfo.forSellArea }}m&sup2;</span>
                </li>
              </ul>
              <div class="search" style="display: none">
                <div class="search-handler">
                  <div>
                    <span class="pre-point"></span>
                    <span>筛选</span>
                    <v-button text="清空" type="danger" plain @click="clearSearch"></v-button>
                  </div>
                </div>
                <div class="search-sall">
                  <span>是否可售</span>
                  <checkbox-plus
                    :value.sync="sallParams"
                    mode="checkbox-plus"
                    :options="sallList"
                    @onChange="sellChange"
                  ></checkbox-plus>
                  <span style="padding-left: 24px">出售状态</span>
                  <checkbox-plus
                    v-model="sallStatusParams"
                    mode="checkbox-plus"
                    :options="sallStatusList"
                    @onChange="sellStatusChange"
                  ></checkbox-plus>
                </div>
                <div class="search-sall">
                  <span>是否可租</span>
                  <checkbox-plus
                    v-model="leaseParams"
                    mode="checkbox-plus"
                    :options="leaseList"
                    @onChange="leaseChange"
                  ></checkbox-plus>
                  <span style="padding-left: 24px">出租状态</span>
                  <checkbox-plus
                    v-model="leaseStatusParams"
                    mode="checkbox-plus"
                    :options="leaseStatusList"
                    @onChange="leaseStatusChange"
                  ></checkbox-plus>
                </div>
                <div class="search-sall">
                  <span>合同状态</span>
                  <checkbox-plus
                    v-model="contractParams"
                    mode="checkbox-plus"
                    :options="contractList"
                    @onChange="expiredStatusChange"
                  ></checkbox-plus>
                  <el-tooltip content="当合同有效期小于或等于180天时，视为即将到期" placement="right-start">
                    <span class="tips"></span>
                  </el-tooltip>
                </div>
              </div>
              <div></div>
              <div class="floorInfo" v-if="floors.length > 0">
                <span class="floorInfo-title">楼层</span>
                <div class="floorInfo-content">
                  <ul class="floor-number">
                    <li :class="{ active: currentFloor === 0 }" @click="selectFloor(0)">全部</li>
                    <li
                      :class="{ active: currentFloor === floor }"
                      v-for="floor in floors"
                      :key="floor"
                      @click="selectFloor(floor)"
                    >{{ `${floor}层` }}</li>
                  </ul>
                  <ul class="floor-color">
                    <li
                      v-for="(item, colorIndex) in floorColor"
                      :key="colorIndex"
                      v-show="item.viewType === viewType"
                    >
                      <span class="pre-block" :style="{ 'background-color': item.color }"></span>
                      <span>{{ item.name }}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <floors-list
                ref="floorsList"
                :listFloor="buildingInfo.listFloor"
                :viewType="viewType"
                :currentFloor="currentFloor"
              />
            </div>
          </template>
          <template v-if="!hasInfo && !hasBuildInfo">
            <div class="empty-wrapper">
              <img :src="emptyImgSrc" />
              <p>请先在右侧导航选择楼幢…</p>
            </div>
          </template>
          <!-- start -->
          <template v-if="!hasInfo && hasBuildInfo">
            <div class="building-info" v-loading="isSearching">
              <builds-list @sendNodeId="sendNodeId" :listBuild="buildingInfoNew" />
            </div>
          </template>
          <!-- end -->
        </div>
      </div>
    </custom-view>
    <!-- 查看附件 -->
    <el-dialog
      :title="dialogTitle"
      width="820px"
      :visible.sync="showDialog"
      @closed="handleCloseDialog"
    >
      <!-- <div class="imgs-wrapper" v-for="(item, index) in buildingBaseInfo.annexVList" :key="index"> -->
      <!-- <el-image 
          style="width: 100px; height: 100px"
          :src="item.annex" 
          :preview-src-list="srcList">
        </el-image>
      <div>{{item.fileName}}</div>-->
      <el-table
        v-if="(buildingBaseInfo.annexVList && buildingBaseInfo.annexVList.length)"
        class="table"
        :data="buildingBaseInfo.annexVList"
        border
        style="width: 100%; margin-right: 20px;"
      >
        <el-table-column prop="fileName" label="附件名称">
          <template slot-scope="scope">
            <el-button
              type="text"
              @click="handlePreview(scope.row, scope.$index)"
            >{{scope.row.fileName}}</el-button>
          </template>
        </el-table-column>
        <el-table-column prop="fileType" label="附件类型" width="180"></el-table-column>
        <el-table-column label="操作" width="100">
          <template slot-scope="scope">
            <a
              style="text-decoration: none; color: #409EFF; font-size: 14px;"
              :href="scope.row.annex"
              :download="scope.row.fileName"
              target="_blank"
            >下载</a>
          </template>
        </el-table-column>
      </el-table>
      <!-- </div> -->
      <div v-if="!(buildingBaseInfo.annexVList && buildingBaseInfo.annexVList.length)">暂无附件</div>
    </el-dialog>
    <!-- 预览 -->
    <el-dialog title="预览" width="840px" :visible.sync="operaDialog">
      <div class="word-wrapper" v-if="type == 'pdf'">
        <div>
          <v-button type="primary" @click="prePage">上一页</v-button>
          <v-button type="primary" @click="nextPage">下一页</v-button>
        </div>
        <div style="margintop: 10px; color: #409eff">{{ pageNum }} / {{ pageTotalNum }}</div>
        <div class="pdf-container">
          <pdf
            :src="pdfsrc"
            :page="pageNum"
            @progress="loadedRatio = $event"
            @num-pages="pageTotalNum = $event"
          ></pdf>
          <!-- <embed :src="pdfsrc" type="application/pdf" width=800 height=800> -->
        </div>
      </div>
      <div class="word-wrapper" v-if="type == 'wordOrExcel'">
        <iframe ref="iframe" name="word" class="iframe-container" :src="wordUrl" frameborder="0"></iframe>
      </div>
      <div class="word-wrapper" v-if="type == 'picture'">
        <el-image style="width: 100%;" :src="picSrc"></el-image>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { CheckboxPlus } from 'components/bussiness'
import {
  getBuildTreeURL,
  getRoomParamByNodeIdURL,
  getCommunityRoomBuildURL,
  getProjectTreeURL,
  getBuildingInfoURL,
  getBaseBuildingInfoURL,
} from './api'
import {
  treeIconMap,
  architectureTypeMap,
  ladderThanFamilyTypeMap,
  buildingTypeMap,
  deliverySituationTypeMap,
  buildingStatusMap,
} from './map'
import FloorsList from './floorInfo'
import BuildsList from './buildInfo'
import { vTree } from 'components/control/index'
import emptyImgSrc from './img/empty.png'
import treeOnImgSrc from './img/treeOn.png'
import treeOffImgSrc from './img/treeOff.png'
import pdf from 'vue-pdf'
import Vue from 'vue'
import { Loading, Scrollbar, Tooltip, Image } from 'element-ui'
Vue.use(Loading.directive)
export default {
  name: 'HouseProfileList',
  components: {
    vTree,
    CheckboxPlus,
    FloorsList,
    BuildsList,
    [Scrollbar.name]: Scrollbar,
    [Tooltip.name]: Tooltip,
    [Image.name]: Image,
    pdf,
  },
  data() {
    return {
      openDrawer:false,
      scrollTop:0,
      operaDialog: false,
      pageNum: 1,
      pageTotalNum: 1,
      type: '',
      loadedRatio: 0,
      pdfsrc: '',
      wordUrl: '',
      architectureTypeMap,
      ladderThanFamilyTypeMap,
      buildingTypeMap,
      deliverySituationTypeMap,
      buildingStatusMap,

      emptyImgSrc,
      treeOnImgSrc,
      treeOffImgSrc,
      treeShow: true,
      hasInfo: false,
      showHouseBtn: false,
      /* start */
      hasBuildInfo: false,
      /* end */
      isSearching: false,
      treeData: [],
      treeProps: {
        children: 'children',
        label: 'name',
        isLeaf: (data, node) => {
          return !data.isParent
        },
      },
      operaIsShow: true,
      buildingInfo: {},
      /* start */
      buildingInfoNew: [],
      /* end */
      viewType: 0, // 0是租赁 1是招商
      currentFloor: 0, // 选中的楼层
      floors: [],
      floorColor: [
        {
          name: '未入驻',
          color: '#1B8CF2',
          viewType: 0,
        },
        {
          name: '已入驻',
          color: '#E0E0E0',
          viewType: 0,
        },
        {
          name: '即将到期',
          color: '#FFE3B6',
          viewType: 0,
        },
        {
          name: '未出售、售卖中',
          color: '#1B8CF2',
          viewType: 1,
        },
        {
          name: '不可售、已出售、出售锁定',
          color: '#E0E0E0',
          viewType: 1,
        },
      ],
      searchParams: {
        canSell: [],
        canLease: [],
        isSell: [],
        isLease: [],
      },
      sallParams: [],
      sallList: [
        {
          label: '可出售',
          value: 1,
        },
        {
          label: '不可出售',
          value: 0,
        },
      ],
      sallStatusParams: [],
      sallStatusList: [
        {
          label: '未出售',
          value: 0,
        },
        {
          label: '售卖中',
          value: 2,
        },
        {
          label: '出售锁定',
          value: 3,
        },
        {
          label: '已出售',
          value: 1,
        },
      ],
      leaseParams: [],
      leaseList: [
        {
          label: '可出租',
          value: 1,
        },
        {
          label: '不可出租',
          value: 0,
        },
      ],
      leaseStatusParams: [],
      leaseStatusList: [
        {
          label: '未出租',
          value: 0,
        },
        {
          label: '招商中',
          value: 2,
        },
        {
          label: '出租锁定',
          value: 3,
        },
        {
          label: '已出租',
          value: 1,
        },
      ],
      contractParams: [],
      contractList: [
        {
          label: '即将到期',
          value: 1,
        },
      ],
      dialogTitle: '附件',
      showDialog: false,
      srcList: [],
      nodeId: '',
      buildingBaseInfo: {
        buildingName: '',
        architectureType: '',
        ladderThanFamily: '',
        buildingType: '',
        deliverySituation: '',
        buildingSort: '',
        buildingStatus: '',
        deliveryTime: '',
      },
      communityName: '',
      communityId: '',
      statisticalArea: '',
      totalArea: '',
      totalHouseCount: '',
      undergroundArea: '',
    }
  },

  computed: {
    // 新增房号权限
    addHousePermission() {
      return (
        this.$store.state.menuStore.menuPermission[
          'communityRoomAction!list.action1'
        ] &&
        this.$store.state.menuStore.menuPermission[
          'communityRoomAction!list.action1'
        ].add === 1
      )
    },
    // 导入权限
    importHousePermission() {
      return (
        this.$store.state.menuStore.menuPermission[
          'communityRoomAction!list.action2'
        ] &&
        this.$store.state.menuStore.menuPermission[
          'communityRoomAction!list.action2'
        ].import === 1
      )
    },
    defaultExpandedKeys() {
      return this.$store.state.houseProfileStore.treeExpandedKeys
    },
    selectedNode() {
      return this.$store.state.houseProfileStore.selectedNode
    },
    blockBuildingName() {
      return this.buildingInfo.blockName
        ? `${this.buildingInfo.blockName}-${this.buildingInfo.buildingNumber}`
        : this.buildingInfo.buildingNumber
    },
    sParams:{
      get(){
        return this.$store.state.roomTreeStore.currentParams
      },
      set(val){
        this.$store.commit('roomTreeStore/UPDATE_CURRENT',val)
      }
    },
  },
  watch:{
    sParams:{
      handler(val){
         this.selectNode(val)
      },
      deep:true,
      immediate:true
    },
  },
  mounted() {
    // this.getNodeFirstList()
    if (Object.keys(this.sParams).length) {
      //  因为多调用了一次，导致页面数据被清空
      // this.selectNode(this.sParams)
    } else {
      // 账号角色类型为项目时
      this.roleIsProject()
    }
    document.addEventListener('click', this.listener, true)
  },
  destroyed() {
    document.removeEventListener('click', this.listener, true)
  },
  methods: {
    roleIsProject() {
      // 该方法作用为更新右侧树的节点
      let userType = this.$store.state.userInfo.userType
      if (userType && userType === '101') {
        let userCommuntiyId = this.$store.state.userInfo.communityId
        let sParams = {
          communityId: userCommuntiyId,
          blockName: '',
          buildingNumber: '',
          nodeLevel: 1,
        }
        this.getBuildingInfoNew(sParams)

        let treeParams = {
          communityId: userCommuntiyId,
          buildingNumber: '',
          blockName: '',
        }
        this.$axios.get(getBuildTreeURL, { params: treeParams }).then((res) => {
          if (res.status === 100) {
            if (res.data) {
              this.nodeId = res.data.buildingNodeId
              window.localStorage.setItem('nodeId', this.nodeId)
              if (
                res.data.buildTrees &&
                Object.keys(res.data.buildTrees).length > 0
              ) {
                this.$store.commit('houseProfileStore/setTreeExpanedKeys', [])
                res.data.buildTrees.forEach((tree) => {
                  if (tree) {
                    this.defaultExpandedKeys.push(tree)
                  }
                })
                this.$store.commit(
                  'houseProfileStore/setTreeExpanedKeys',
                  this.defaultExpandedKeys
                )
              }
            }
          }
        })
      }
    },
    async getNodeFirstList() {
      let list = await this.getProjectTree(0)
      this.treeData = list
      this.operaIsShow = true
    },
    getProjectTree(level, id) {
      if (level >= 4) {
        // 只到幢
        return
      }
      let params = {
        nodeLevel: level,
      }
      id && (params.id = id)
      return new Promise((resolve, reject) => {
        this.$axios.get(getProjectTreeURL, { params }).then((res) => {
          if (res.status === 100) {
            let { data } = res
            data.forEach((val) => {
              val.isParent = val.nodeLevel >= 4 ? 0 : val.isParent
              val.icon = treeIconMap[val.iconSkin]
            })
            resolve(data)
          }
        })
      })
    },
    async treeLoad(node, resolve) {
      let { nodeLevel, id } = node.data
      if (id) {
        this.defaultExpandedKeys.push(id)
      }
      this.$store.commit(
        'houseProfileStore/setTreeExpanedKeys',
        this.defaultExpandedKeys
      )
      let list = await this.getProjectTree(nodeLevel, id)
      setTimeout(() => {
        return resolve(list)
      }, 500)
    },
    create() {
      this.$router.push({
        name: 'validHouseForm',
      })
    },
    goPageImport() {
      this.$router.push({
        name: 'validHouseImport',
      })
    },
    selectNode(data) {
      this.showHouseBtn = false
      let { id, name, nodeLevel } = data
      if(!id) return
      this.nodeId = id
      window.localStorage.setItem('nodeId', id)
      let params = {
        nodeId: id,
        nodeName: name,
        nodeLevel: nodeLevel,
      }
      let _this_ = this
      this.$axios.get(getRoomParamByNodeIdURL, { params }).then((res) => {
        if (res.status === 100) {
          let { blockName, buildingNumber, communityId, communityName, room } = res.data
          this.communityName = communityName
          this.communityId = communityId
          window.localStorage.setItem('currentCommunityId', communityId)
          // let sParams = { blockName, buildingNumber, communityId }
          let sParams = {
            blockName,
            buildingNumber,
            communityId,
            nodeLevel,
            room,
          }
          if (nodeLevel >= 1 && nodeLevel <= 3) {
            // 查询楼幢接口
            _this_.getBuildingInfoNew(sParams)
          } else if (nodeLevel === 4) {
            // if (nodeLevel === 4) {
            // 查询楼幢接口
            _this_.$store.commit('houseProfileStore/setSelectedNode', sParams)
            _this_.getBuildingInfo(sParams)
          }
        }
      })
    },
    // 获取小区的楼幢信息
    getBuildingInfoNew(sParams) {
      let _this_ = this
      _this_.isSearching = true
      _this_.hasBuildInfo = true
      _this_.hasInfo = false
      _this_.$axios
        .get(getCommunityRoomBuildURL, { params: sParams })
        .then((res) => {
          _this_.isSearching = false
          if (res.status === 100) {
            if (res.data) {
              _this_.hasBuildInfo = true
              _this_.buildingInfoNew = res.data
              _this_.buildingInfo = {}
            } else {
              _this_.hasBuildInfo = false
            }
          } else {
            _this_.hasBuildInfo = false
          }
        })
    },
    // 获取这幢楼的楼层信息
    getBuildingInfo(sParams) {
      let _this_ = this
      _this_.isSearching = true
      this.showHouseBtn = true
      _this_.clearSearch()
      console.log(sParams)
      if (!this.nodeId) {
        this.nodeId = window.localStorage.getItem('nodeId')
      }
      _this_.$axios.get(getBuildingInfoURL, { params: sParams }).then((res) => {
        _this_.isSearching = false
        if (res.status === 100) {
          if (res.data) {
            _this_.hasInfo = true
            let listFloor = res.data.listFloor
            // 供编辑页使用
            // "statisticalArea":0,  统计面积
            // "totalArea":20000.00,建筑面积
            // "totalHouseCount":1,房号数
            // "undergroundArea":0 地下室面积
            const {
              communityName,
              statisticalArea,
              totalArea,
              totalHouseCount,
              undergroundArea,
            } = res.data
            this.communityName = communityName
            this.statisticalArea = statisticalArea
            this.totalArea = totalArea
            this.totalHouseCount = totalHouseCount
            this.undergroundArea = undergroundArea
            let name = ''
            name =
              res.data.regionName +
              '-' +
              res.data.communityName +
              (res.data.blockName
                ? '-' + res.data.blockName + '-' + res.data.buildingNumber
                : '-' + res.data.buildingNumber)
            res.data.name = name
            _this_.currentFloor = 0
            _this_.floors = []
            let floorCanLeaseNumber = 0 // 可以出租的数量
            let floorCannotLeaseNumber = 0 // 不可出租的数量
            let floorIsLease0Number = 0 // 未出租
            let floorIsLease1Number = 0 // 已出租
            let floorIsLease2Number = 0 // 招商中
            let floorIsLease3Number = 0 // 出租锁定
            let floorCanSellNumber = 0 // 可以售卖的数量
            let floorCannotSellNumber = 0 // 不可售卖的数量
            let floorIsSell0Number = 0 // 未出售
            let floorIsSell1Number = 0 // 已出售
            let floorIsSell2Number = 0 // 售卖中
            let floorIsSell3Number = 0 // 出售锁定
            let floorExpiredNumber = 0 // 即将到期的数量
            if (listFloor && listFloor.length) {
              listFloor.forEach((floor) => {
                _this_.floors.push(floor.floor)
                let floorTotalArea = floor.floorTotalArea || 100
                let roomList = floor.roomList
                floor.tipValue = false
                if (roomList && roomList.length) {
                  roomList.forEach((house) => {
                    house.name = house.unit
                      ? house.unit + '-' + house.room
                      : house.room
                    house.rate = house.buildingArea / floorTotalArea
                    house.percent = _this_.percentAge(
                      house.buildingArea,
                      floorTotalArea
                    )
                    house.isCover = false
                    // 出租状态
                    if (house.canLease === 1) {
                      // 可以出租
                      floorCanLeaseNumber++
                      if (house.isLease === 0) {
                        // 未出租
                        floorIsLease0Number++
                      } else if (house.isLease === 1) {
                        // 已出租
                        floorIsLease1Number++
                      } else if (house.isLease === 2) {
                        // 招商中
                        floorIsLease2Number++
                      } else if (house.isLease === 3) {
                        // 出租锁定
                        floorIsLease3Number++
                      }
                    } else if (house.canLease === 0) {
                      // 不可出租
                      floorCannotLeaseNumber++
                    }
                    // 出售状态
                    if (house.canSell === 1) {
                      // 可以出售
                      floorCanSellNumber++
                      if (house.isSell === 0) {
                        // 未出售
                        floorIsSell0Number++
                      } else if (house.isSell === 1) {
                        // 已出售
                        floorIsLease1Number++
                      } else if (house.isSell === 2) {
                        // 售卖中
                        floorIsSell2Number++
                      } else if (house.isSell === 3) {
                        // 出售锁定
                        floorIsSell3Number++
                      }
                    } else if (house.canSell === 0) {
                      // 不可出售
                      floorCannotSellNumber++
                    }
                    // 即将过期
                    if (house.expired === 1) {
                      floorExpiredNumber++
                    }
                  })
                }
                floor.canLeaseNumber = floorCanLeaseNumber
                floor.cannotLeaseNumber = floorCannotLeaseNumber
                floor.isLease0Number = floorIsLease0Number // 未出租
                floor.isLease1Number = floorIsLease1Number // 已出租
                floor.isLease2Number = floorIsLease2Number // 招商中
                floor.isLease3Number = floorIsLease3Number // 出租锁定
                floor.canSellNumber = floorCanSellNumber
                floor.cannotSellNumber = floorCannotSellNumber
                floor.isSell0Number = floorIsSell0Number // 未出售
                floor.isSell1Number = floorIsSell1Number // 已出售
                floor.isSell2Number = floorIsSell2Number // 售卖中
                floor.isSell3Number = floorIsSell3Number // 出售锁定
                floor.expiredNumber = floorExpiredNumber
              })
            }
            _this_.buildingInfo = res.data
            _this_.floors = _this_.floors.sort((a, b) => {
              return a - b
            })
          }
        }
      })
      _this_.$axios
        .get(getBaseBuildingInfoURL, { params: { id: _this_.nodeId } })
        .then((res) => {
          if (res.status === 100) {
            if (res.data) {
              this.buildingBaseInfo = res.data
              res.data.annexVList.map((item, index) => {
                item.fileType = item.fileName.split('.')[1]
                this.buildingBaseInfo[index] = item
              })
            } else {
              this.buildingBaseInfo = {}
            }
          }
        })
    },
    // 百分比转换
    percentAge(num1, num2) {
      return Math.round((num1 / num2) * 10000) / 100.0 + '%'
    },
    // 隐藏出现tree
    treeSwith() {
      this.treeShow = !this.treeShow
    },
    clearSearch() {
      this.sallParams = []
      this.leaseParams = []
      this.sallStatusParams = []
      this.leaseStatusParams = []
      this.contractParams = []
      this.removeCover()
    },
    sellChange(index, pureCheckedOptions, checkedOptions) {
      this.searchParams.canSell = pureCheckedOptions
      this.filterFloors()
    },
    sellStatusChange(index, pureCheckedOptions, checkedOptions) {
      this.searchParams.isSell = pureCheckedOptions
      this.filterFloors()
    },
    leaseChange(index, pureCheckedOptions, checkedOptions) {
      this.searchParams.canLease = pureCheckedOptions
      this.filterFloors()
    },
    leaseStatusChange(index, pureCheckedOptions, checkedOptions) {
      this.searchParams.isLease = pureCheckedOptions
      this.filterFloors()
    },
    expiredStatusChange(index, pureCheckedOptions, checkedOptions) {
      this.searchParams.expired = pureCheckedOptions
      this.filterFloors()
    },
    filterFloors(searchKey) {
      let _this_ = this
      let listFloor = this.buildingInfo.listFloor
      if (listFloor && listFloor.length) {
        listFloor.forEach((floor) => {
          let roomList = floor.roomList
          if (roomList && roomList.length) {
            roomList.forEach((room) => {
              room.isCover = false
              Object.keys(_this_.searchParams).forEach(function (key) {
                let searchValues = _this_.searchParams[key]
                if (key === 'canSell') {
                  if (searchValues.length) {
                    let searchFlag = true
                    searchFlag = searchValues.includes(room.canSell)
                    if (!searchFlag) {
                      room.isCover = true
                    }
                  }
                }
                if (key === 'isSell') {
                  if (searchValues.length) {
                    let sellStatusFlag = true
                    sellStatusFlag = searchValues.some((key) => {
                      return key === room.isSell
                    })
                    if (!sellStatusFlag) {
                      room.isCover = true
                    }
                  }
                }
                if (key === 'canLease') {
                  if (searchValues.length) {
                    let leaseFlag = true
                    leaseFlag = searchValues.includes(room.canLease)
                    if (!leaseFlag) {
                      room.isCover = true
                    }
                  }
                }
                if (key === 'isLease') {
                  if (searchValues.length) {
                    let leaseStatusFlag = true
                    leaseStatusFlag = searchValues.some((key) => {
                      return key === room.isLease
                    })
                    if (!leaseStatusFlag) {
                      room.isCover = true
                    }
                  }
                }
                if (key === 'expired') {
                  if (searchValues.length) {
                    let expiredFlag = true
                    expiredFlag = searchValues.some((key) => {
                      return key === room.expired
                    })
                    if (!expiredFlag) {
                      room.isCover = true
                    }
                  }
                }
              })
            })
          }
        })
      }
    },
    removeCover() {
      let listFloor = this.buildingInfo.listFloor
      if (listFloor && listFloor.length) {
        listFloor.forEach((floor) => {
          let roomList = floor.roomList
          if (roomList && roomList.length) {
            roomList.forEach((room) => {
              room.isCover = false
            })
          }
        })
      }
    },
    // 选择楼层
    selectFloor(floor) {
      this.currentFloor = floor
    },
    // 切换视图
    swichView() {
      this.viewType = Number(!this.viewType)
    },
    // listener
    listener(e) {
      const _this_ = this
      if (_this_.$refs.floorsList) {
        let orgInfo = _this_.$refs.floorsList.$refs.orgInfo
        if (orgInfo.contains(e.target)) {
          console.log('zaineo')
        } else {
          if (
            e.target.className.indexOf('floor-content-house') === -1 &&
            e.target.parentNode.className.indexOf('floor-content-house') === -1
          ) {
            _this_.$refs.floorsList.dialogVisible = false
          }
        }
      }
    },
    // 新增楼宇
    addBuilding() {},
    // 楼宇编辑
    edit() {
      this.$router.push({
        name: 'houseProfileForm',
        query: {
          nodeId: this.nodeId,
          communityName: this.communityName,
          communityId:
            this.communityId || localStorage.getItem('currentCommunityId'),
          blockBuildingName: this.blockBuildingName,
          statisticalArea: this.statisticalArea,
          totalArea: this.totalArea,
          undergroundArea: this.undergroundArea,
        },
      })
    },
    handleCloseDialog() {
      this.showDialog = false
    },
    /**
     * @description: 查看附件
     */
    checkFiles() {
      this.showDialog = true
    },
    // 子组件向外触发事件
    sendNodeId(id) {
      this.nodeId = id
    },
    handlePreview(row, index) {
      this.operaDialog = true
      console.log(row)
      const { fileType, annex } = row
      if (/docx|doc|xls|xlsx|txt/.test(fileType)) {
        this.type = 'wordOrExcel'
        this.wordUrl = `https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(
          annex
        )}`
      } else if (fileType === 'pdf') {
        this.type = 'pdf'
        this.pdfsrc = annex
      } else if (/png|jpg|jpeg/.test(fileType)) {
        this.type = 'picture'
        this.picSrc = annex
      }
    },
    // 上一页
    prePage() {
      let page = this.pageNum
      page = page > 1 ? page - 1 : this.pageTotalNum
      this.pageNum = page
    },
    // 下一页
    nextPage() {
      let page = this.pageNum
      page = page < this.pageTotalNum ? page + 1 : 1
      this.pageNum = page
    },
  },
}
</script>
<style lang="scss" scoped>
.houseProfileList-wrapper {
  background: #fff;
  .custom-view-container {
    height: 100%;
    padding-right: 0;
  }
  .header {
    position: absolute;
    top: 20px;
    right: 20px;
    .v-button-container {
      margin-left: 8px;
    }
  }
  .content-wrapper {
    font-size: 14px;
    display: flex;
    border-top: 1px solid #ebebeb;
    margin-bottom: 28px;
    padding: 16px 0;
    width: 100%;
    .tree-group {
      width: 230px;
      height: 100%;
      overflow: auto;
      box-sizing: border-box;
      text-align: left;
    }
    .treeSwith {
      cursor: pointer;
      display: flex;
      img {
        width: 36px;
        height: 80px;
        padding-left: -5px;
      }
      &-bar {
        width: 8px;
        height: 160px;
        background: linear-gradient(
          to top left,
          rgba(228, 228, 228, 1) 0%,
          rgba(255, 255, 255, 1) 100%
        );
      }
    }
    .right-content {
      flex: 1;
      overflow-x: hidden;
      margin-left: 10px;
      position: relative;
      .viewtype-wrapper {
        margin-bottom: 10px;
        line-height: 29px;
        font-size: 21px;
        font-weight: 600;
        display: flex;
        .swichView {
          font-weight: 400;
          display: flex;
          align-items: center;
          // margin-left: 16px;
          height: 29px;
          background: rgba(123, 192, 255, 1);
          border-radius: 14px;
          color: #fff;
          font-size: 14px;
          line-height: 16px;
          cursor: pointer;
          .name {
            padding: 0 16px;
            border-right: 1px solid #92c6ff;
          }
          .swich {
            font-size: 12px;
            padding: 0 10px;
          }
        }
      }
      .building-info {
        .build-base-info {
          margin-bottom: 40px;
          .base-title {
            margin-bottom: 10px;
          }
          .base-desc {
            display: flex;
            margin-top: 10px;
            .desc-item {
              width: 260px;
              margin-right: 10px;
              div {
                margin-top: 5px;
              }
            }
          }
        }
        .viewtype-wrapper {
          margin-bottom: 10px;
          line-height: 29px;
          font-size: 21px;
          font-weight: 600;
          display: flex;
          .swichView {
            font-weight: 400;
            display: flex;
            align-items: center;
            // margin-left: 16px;
            height: 29px;
            background: rgba(123, 192, 255, 1);
            border-radius: 14px;
            color: #fff;
            font-size: 14px;
            line-height: 16px;
            cursor: pointer;
            .name {
              padding: 0 16px;
              border-right: 1px solid #92c6ff;
            }
            .swich {
              font-size: 12px;
              padding: 0 10px;
            }
          }
        }

        .area {
          display: flex;
          align-items: center;
          li {
            margin-right: 10px;
            width: 260px;
            height: 64px;
            border-radius: 4px;
            color: #fff;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
          }
          .blue {
            background: #9ac1e6;
          }
          .orange {
            background: #edc3b3;
          }
          .purple {
            background: #beb9f2;
          }
          .green {
            background: #6fcdc8;
          }
        }
        .base {
          color: #666666;
          margin: 20px 0;
          display: flex;
          justify-content: space-between;
        }
      }
      .search {
        &-handler {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 16px 0;
          .v-button-container {
            width: 70px;
            margin-left: 20px;
          }
        }
        &-sall {
          display: flex;
          align-items: center;
          margin-bottom: 10px;
          span {
            line-height: 20px;
            padding-right: 8px;
          }
          .tips {
            padding: 0;
            margin-left: 8px;
            display: inline-block;
            width: 20px;
            height: 20px;
            background-image: url('./img/question.png');
            background-size: 100% 100%;
            cursor: pointer;
          }
        }
        &-checkbox {
          padding: 16px 0 6px 0;
          border-bottom: 1px solid #e0e0e0;
        }
      }
      .floorInfo {
        display: flex;
        margin: 20px 12px 0 0;
        &-title {
          width: 100px;
          font-size: 18px;
          font-weight: 700;
          line-height: 35px;
          vertical-align: middle;
        }
        &-content {
          display: flex;
          justify-content: space-between;
          flex: 1;
          .floor-number {
            display: flex;
            color: #aaaaaa;
            margin: 0;
            border-radius: 4px;
            flex: 1;
            flex-wrap: wrap;
            li {
              width: 60px;
              height: 32px;
              border-top: 1px solid #c5c5c5;
              border-bottom: 1px solid #c5c5c5;
              border-right: 1px solid #c5c5c5;
              line-height: 32px;
              text-align: center;
              cursor: pointer;
            }
            li:nth-child(7n + 7) {
              border-radius: 0px 4px 4px 0px;
              border-right: 1px solid #c5c5c5;
            }
            li:nth-child(7n + 8) {
              border-radius: 4px 0 0 4px;
              border-left: 1px solid #c5c5c5;
            }
            li:first-child {
              border-radius: 4px 0 0 4px;
              border-left: 1px solid #c5c5c5;
            }
            li:last-child {
              border-radius: 0px 4px 4px 0px;
            }
            .active {
              background: rgba(227, 240, 255, 1);
              border: 1px solid rgba(27, 140, 242, 1);
              color: rgba(27, 140, 242, 1);
            }
            li.active:first-child {
              border-left: 1px solid rgba(27, 140, 242, 1);
            }
          }
          .floor-color {
            width: 360px;
            flex: 0 0 360px;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            li {
              display: flex;
              align-items: center;
              justify-content: center;
              margin-left: 16px;
            }
          }
        }
      }
      .empty-wrapper {
        width: 100%;
        height: 100%;
        min-height: 600px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: #333333;
      }
    }

    .pre-block {
      display: inline-block;
      border-radius: 2px;
      background-color: #7ac943;
      height: 16px;
      width: 16px;
      margin-right: 5px;
    }
  }
  ::v-deep .el-dialog__body {
    display: flex;
    text-align: center;
    // justify-content: flex-end;
    flex-wrap: wrap;
    padding-right: 0 !important;
    .imgs-wrapper {
      display: flex;
      flex-direction: column;
      justify-items: center;
      align-items: center;
      margin-right: 15px;
      margin-bottom: 5px;
      div {
        margin-top: 5px;
      }
    }
  }
}
</style>
<style lang="scss">
.houseProfileList-wrapper {
  .checkbox-plus-wrapper {
    .check-item {
      .btn {
        min-width: 96px;
      }
    }
  }
}
.word-wrapper {
  width: 100%;
  margin-right: 20px;
  .pdf-container {
    height: 400px;
    overflow-y: scroll;
  }
  .iframe-container {
    width: 100%;
    height: 400px;
    flex: 1;
  }
}
</style>
