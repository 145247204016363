var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { ref: "builds", staticClass: "builds-wrapper" },
    _vm._l(_vm.listBuild, function (build, buildIndex) {
      return _c(
        "div",
        {
          key: buildIndex,
          staticClass: "build",
          on: {
            click: function ($event) {
              return _vm.toInfo(build)
            },
          },
        },
        [
          _c("div", { staticClass: "top-height" }, [
            _c(
              "div",
              { staticClass: "build-contain" },
              [
                [
                  _c("span", { staticClass: "build-span" }, [
                    _vm._v(_vm._s(build.buildingNumber)),
                  ]),
                ],
              ],
              2
            ),
          ]),
          _c("div", [
            _c(
              "div",
              { staticClass: "unit-contain" },
              [
                [
                  _c(
                    "span",
                    {
                      staticClass: "font-16 font-b",
                      attrs: { title: `单元数:${build.unitNum}` },
                    },
                    [_vm._v("单元数:" + _vm._s(build.unitNum))]
                  ),
                ],
              ],
              2
            ),
            _c(
              "div",
              { staticClass: "floor-contain" },
              [
                [
                  _c(
                    "span",
                    {
                      staticClass: "font-16 font-b",
                      attrs: { title: `楼层:${build.floorNum}` },
                    },
                    [_vm._v("楼层:" + _vm._s(build.floorNum))]
                  ),
                ],
              ],
              2
            ),
          ]),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }