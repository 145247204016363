var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { ref: "houseProfile", staticClass: "houseProfileList-wrapper" },
    [
      _c("custom-view", [
        _c(
          "div",
          { staticClass: "header" },
          [
            _c("v-button", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showHouseBtn,
                  expression: "showHouseBtn",
                },
              ],
              attrs: { text: "编辑" },
              on: { click: _vm.edit },
            }),
            _c("v-button", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showHouseBtn,
                  expression: "showHouseBtn",
                },
              ],
              attrs: { text: "查看附件" },
              on: { click: _vm.checkFiles },
            }),
            _c("v-button", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.addHousePermission,
                  expression: "addHousePermission",
                },
              ],
              attrs: { text: "新增房号" },
              on: { click: _vm.create },
            }),
            _c("v-button", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.importHousePermission,
                  expression: "importHousePermission",
                },
              ],
              attrs: { text: "批量导入" },
              on: { click: _vm.goPageImport },
            }),
          ],
          1
        ),
        _c("div", { staticClass: "content-wrapper" }, [
          _c(
            "div",
            { staticClass: "right-content" },
            [
              _vm.hasInfo
                ? [
                    _c("div", { staticClass: "viewtype-wrapper" }, [
                      _c("span", [_vm._v(_vm._s(_vm.blockBuildingName))]),
                    ]),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "loading",
                            rawName: "v-loading",
                            value: _vm.isSearching,
                            expression: "isSearching",
                          },
                        ],
                        staticClass: "building-info",
                      },
                      [
                        _c("div", { staticClass: "build-base-info" }, [
                          _c("ul", { staticClass: "area" }, [
                            _c("li", { staticClass: "blue" }, [
                              _c("span", [_vm._v("总户数：")]),
                              _c("span", [
                                _vm._v(_vm._s(this.totalHouseCount) + "户"),
                              ]),
                            ]),
                            _c("li", { staticClass: "orange" }, [
                              _c("span", [_vm._v("楼层数：")]),
                              _c("span", [
                                _vm._v(
                                  _vm._s(_vm.buildingBaseInfo.buildingNum) +
                                    "层"
                                ),
                              ]),
                            ]),
                            _c("li", { staticClass: "green" }, [
                              _c("span", [_vm._v("建筑面积：")]),
                              _c("span", [
                                _vm._v(_vm._s(this.totalArea) + "m²"),
                              ]),
                            ]),
                          ]),
                          _c("div", { staticClass: "base-desc" }, [
                            _c("div", { staticClass: "desc-item" }, [
                              _c("div", [
                                _vm._v(
                                  "所属项目：" + _vm._s(_vm.communityName)
                                ),
                              ]),
                              _c("div", [
                                _vm._v(
                                  "建筑类型：" +
                                    _vm._s(
                                      _vm.architectureTypeMap[
                                        _vm.buildingBaseInfo.architectureType
                                      ]
                                    )
                                ),
                              ]),
                              _c("div", [
                                _vm._v(
                                  "    梯户比：" +
                                    _vm._s(
                                      _vm.ladderThanFamilyTypeMap[
                                        _vm.buildingBaseInfo.ladderThanFamily
                                      ]
                                    )
                                ),
                              ]),
                            ]),
                            _c("div", { staticClass: "desc-item" }, [
                              _c("div", [
                                _vm._v(
                                  "楼宇名称：" + _vm._s(_vm.blockBuildingName)
                                ),
                              ]),
                              _c("div", [
                                _vm._v(
                                  "楼宇类型：" +
                                    _vm._s(
                                      _vm.buildingTypeMap[
                                        _vm.buildingBaseInfo.buildingType
                                      ]
                                    )
                                ),
                              ]),
                              _c("div", [
                                _vm._v(
                                  "交付情况：" +
                                    _vm._s(
                                      _vm.deliverySituationTypeMap[
                                        _vm.buildingBaseInfo.deliverySituation
                                      ]
                                    )
                                ),
                              ]),
                            ]),
                            _c("div", { staticClass: "desc-item" }, [
                              _c("div", [
                                _vm._v(
                                  "楼宇编号：" +
                                    _vm._s(_vm.buildingBaseInfo.buildingSort)
                                ),
                              ]),
                              _c("div", [
                                _vm._v(
                                  "楼宇状态：" +
                                    _vm._s(
                                      _vm.buildingStatusMap[
                                        _vm.buildingBaseInfo.buildingStatus
                                      ]
                                    )
                                ),
                              ]),
                              _c("div", [
                                _vm._v(
                                  "交付时间：" +
                                    _vm._s(_vm.buildingBaseInfo.deliveryTime)
                                ),
                              ]),
                            ]),
                          ]),
                        ]),
                        _c("div", { staticClass: "viewtype-wrapper" }, [
                          _c(
                            "div",
                            {
                              staticClass: "swichView",
                              on: { click: _vm.swichView },
                            },
                            [
                              _c("span", { staticClass: "name" }, [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(
                                      _vm.viewType === 0
                                        ? "租赁视图"
                                        : "销售视图"
                                    ) +
                                    "\n                "
                                ),
                              ]),
                              _c("span", { staticClass: "swich" }, [
                                _vm._v(
                                  "\n                  切换\n                  "
                                ),
                                _c("i", { staticClass: "el-icon-caret-right" }),
                              ]),
                            ]
                          ),
                        ]),
                        _c("ul", { staticClass: "area" }, [
                          _c("li", { staticClass: "blue" }, [
                            _c("span", [_vm._v("总面积：")]),
                            _c("span", [
                              _vm._v(_vm._s(_vm.buildingInfo.totalArea) + "m²"),
                            ]),
                          ]),
                          _c(
                            "li",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.viewType === 0,
                                  expression: "viewType === 0",
                                },
                              ],
                              staticClass: "orange",
                            },
                            [
                              _c("span", [_vm._v("已租面积：")]),
                              _c("span", [
                                _vm._v(
                                  _vm._s(_vm.buildingInfo.leaseArea) + "m²"
                                ),
                              ]),
                            ]
                          ),
                          _c(
                            "li",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.viewType === 0,
                                  expression: "viewType === 0",
                                },
                              ],
                              staticClass: "green",
                            },
                            [
                              _c("span", [_vm._v("待租面积：")]),
                              _c("span", [
                                _vm._v(
                                  _vm._s(_vm.buildingInfo.forLeaseArea) + "m²"
                                ),
                              ]),
                            ]
                          ),
                          _c(
                            "li",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.viewType === 1,
                                  expression: "viewType === 1",
                                },
                              ],
                              staticClass: "purple",
                            },
                            [
                              _c("span", [_vm._v("已售面积：")]),
                              _c("span", [
                                _vm._v(
                                  _vm._s(_vm.buildingInfo.sellArea) + "m²"
                                ),
                              ]),
                            ]
                          ),
                          _c(
                            "li",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.viewType === 1,
                                  expression: "viewType === 1",
                                },
                              ],
                              staticClass: "orange",
                            },
                            [
                              _c("span", [_vm._v("待售面积：")]),
                              _c("span", [
                                _vm._v(
                                  _vm._s(_vm.buildingInfo.forSellArea) + "m²"
                                ),
                              ]),
                            ]
                          ),
                        ]),
                        _c(
                          "div",
                          {
                            staticClass: "search",
                            staticStyle: { display: "none" },
                          },
                          [
                            _c("div", { staticClass: "search-handler" }, [
                              _c(
                                "div",
                                [
                                  _c("span", { staticClass: "pre-point" }),
                                  _c("span", [_vm._v("筛选")]),
                                  _c("v-button", {
                                    attrs: {
                                      text: "清空",
                                      type: "danger",
                                      plain: "",
                                    },
                                    on: { click: _vm.clearSearch },
                                  }),
                                ],
                                1
                              ),
                            ]),
                            _c(
                              "div",
                              { staticClass: "search-sall" },
                              [
                                _c("span", [_vm._v("是否可售")]),
                                _c("checkbox-plus", {
                                  attrs: {
                                    value: _vm.sallParams,
                                    mode: "checkbox-plus",
                                    options: _vm.sallList,
                                  },
                                  on: {
                                    "update:value": function ($event) {
                                      _vm.sallParams = $event
                                    },
                                    onChange: _vm.sellChange,
                                  },
                                }),
                                _c(
                                  "span",
                                  { staticStyle: { "padding-left": "24px" } },
                                  [_vm._v("出售状态")]
                                ),
                                _c("checkbox-plus", {
                                  attrs: {
                                    mode: "checkbox-plus",
                                    options: _vm.sallStatusList,
                                  },
                                  on: { onChange: _vm.sellStatusChange },
                                  model: {
                                    value: _vm.sallStatusParams,
                                    callback: function ($$v) {
                                      _vm.sallStatusParams = $$v
                                    },
                                    expression: "sallStatusParams",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "search-sall" },
                              [
                                _c("span", [_vm._v("是否可租")]),
                                _c("checkbox-plus", {
                                  attrs: {
                                    mode: "checkbox-plus",
                                    options: _vm.leaseList,
                                  },
                                  on: { onChange: _vm.leaseChange },
                                  model: {
                                    value: _vm.leaseParams,
                                    callback: function ($$v) {
                                      _vm.leaseParams = $$v
                                    },
                                    expression: "leaseParams",
                                  },
                                }),
                                _c(
                                  "span",
                                  { staticStyle: { "padding-left": "24px" } },
                                  [_vm._v("出租状态")]
                                ),
                                _c("checkbox-plus", {
                                  attrs: {
                                    mode: "checkbox-plus",
                                    options: _vm.leaseStatusList,
                                  },
                                  on: { onChange: _vm.leaseStatusChange },
                                  model: {
                                    value: _vm.leaseStatusParams,
                                    callback: function ($$v) {
                                      _vm.leaseStatusParams = $$v
                                    },
                                    expression: "leaseStatusParams",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "search-sall" },
                              [
                                _c("span", [_vm._v("合同状态")]),
                                _c("checkbox-plus", {
                                  attrs: {
                                    mode: "checkbox-plus",
                                    options: _vm.contractList,
                                  },
                                  on: { onChange: _vm.expiredStatusChange },
                                  model: {
                                    value: _vm.contractParams,
                                    callback: function ($$v) {
                                      _vm.contractParams = $$v
                                    },
                                    expression: "contractParams",
                                  },
                                }),
                                _c(
                                  "el-tooltip",
                                  {
                                    attrs: {
                                      content:
                                        "当合同有效期小于或等于180天时，视为即将到期",
                                      placement: "right-start",
                                    },
                                  },
                                  [_c("span", { staticClass: "tips" })]
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                        _c("div"),
                        _vm.floors.length > 0
                          ? _c("div", { staticClass: "floorInfo" }, [
                              _c("span", { staticClass: "floorInfo-title" }, [
                                _vm._v("楼层"),
                              ]),
                              _c("div", { staticClass: "floorInfo-content" }, [
                                _c(
                                  "ul",
                                  { staticClass: "floor-number" },
                                  [
                                    _c(
                                      "li",
                                      {
                                        class: {
                                          active: _vm.currentFloor === 0,
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.selectFloor(0)
                                          },
                                        },
                                      },
                                      [_vm._v("全部")]
                                    ),
                                    _vm._l(_vm.floors, function (floor) {
                                      return _c(
                                        "li",
                                        {
                                          key: floor,
                                          class: {
                                            active: _vm.currentFloor === floor,
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.selectFloor(floor)
                                            },
                                          },
                                        },
                                        [_vm._v(_vm._s(`${floor}层`))]
                                      )
                                    }),
                                  ],
                                  2
                                ),
                                _c(
                                  "ul",
                                  { staticClass: "floor-color" },
                                  _vm._l(
                                    _vm.floorColor,
                                    function (item, colorIndex) {
                                      return _c(
                                        "li",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value:
                                                item.viewType === _vm.viewType,
                                              expression:
                                                "item.viewType === viewType",
                                            },
                                          ],
                                          key: colorIndex,
                                        },
                                        [
                                          _c("span", {
                                            staticClass: "pre-block",
                                            style: {
                                              "background-color": item.color,
                                            },
                                          }),
                                          _c("span", [
                                            _vm._v(_vm._s(item.name)),
                                          ]),
                                        ]
                                      )
                                    }
                                  ),
                                  0
                                ),
                              ]),
                            ])
                          : _vm._e(),
                        _c("floors-list", {
                          ref: "floorsList",
                          attrs: {
                            listFloor: _vm.buildingInfo.listFloor,
                            viewType: _vm.viewType,
                            currentFloor: _vm.currentFloor,
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                : _vm._e(),
              !_vm.hasInfo && !_vm.hasBuildInfo
                ? [
                    _c("div", { staticClass: "empty-wrapper" }, [
                      _c("img", { attrs: { src: _vm.emptyImgSrc } }),
                      _c("p", [_vm._v("请先在右侧导航选择楼幢…")]),
                    ]),
                  ]
                : _vm._e(),
              !_vm.hasInfo && _vm.hasBuildInfo
                ? [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "loading",
                            rawName: "v-loading",
                            value: _vm.isSearching,
                            expression: "isSearching",
                          },
                        ],
                        staticClass: "building-info",
                      },
                      [
                        _c("builds-list", {
                          attrs: { listBuild: _vm.buildingInfoNew },
                          on: { sendNodeId: _vm.sendNodeId },
                        }),
                      ],
                      1
                    ),
                  ]
                : _vm._e(),
            ],
            2
          ),
        ]),
      ]),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.dialogTitle,
            width: "820px",
            visible: _vm.showDialog,
          },
          on: {
            "update:visible": function ($event) {
              _vm.showDialog = $event
            },
            closed: _vm.handleCloseDialog,
          },
        },
        [
          _vm.buildingBaseInfo.annexVList &&
          _vm.buildingBaseInfo.annexVList.length
            ? _c(
                "el-table",
                {
                  staticClass: "table",
                  staticStyle: { width: "100%", "margin-right": "20px" },
                  attrs: { data: _vm.buildingBaseInfo.annexVList, border: "" },
                },
                [
                  _c("el-table-column", {
                    attrs: { prop: "fileName", label: "附件名称" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handlePreview(
                                        scope.row,
                                        scope.$index
                                      )
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(scope.row.fileName))]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1155091023
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "fileType",
                      label: "附件类型",
                      width: "180",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: { label: "操作", width: "100" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "a",
                                {
                                  staticStyle: {
                                    "text-decoration": "none",
                                    color: "#409EFF",
                                    "font-size": "14px",
                                  },
                                  attrs: {
                                    href: scope.row.annex,
                                    download: scope.row.fileName,
                                    target: "_blank",
                                  },
                                },
                                [_vm._v("下载")]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2055632357
                    ),
                  }),
                ],
                1
              )
            : _vm._e(),
          !(
            _vm.buildingBaseInfo.annexVList &&
            _vm.buildingBaseInfo.annexVList.length
          )
            ? _c("div", [_vm._v("暂无附件")])
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: { title: "预览", width: "840px", visible: _vm.operaDialog },
          on: {
            "update:visible": function ($event) {
              _vm.operaDialog = $event
            },
          },
        },
        [
          _vm.type == "pdf"
            ? _c("div", { staticClass: "word-wrapper" }, [
                _c(
                  "div",
                  [
                    _c(
                      "v-button",
                      {
                        attrs: { type: "primary" },
                        on: { click: _vm.prePage },
                      },
                      [_vm._v("上一页")]
                    ),
                    _c(
                      "v-button",
                      {
                        attrs: { type: "primary" },
                        on: { click: _vm.nextPage },
                      },
                      [_vm._v("下一页")]
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticStyle: { margintop: "10px", color: "#409eff" } },
                  [
                    _vm._v(
                      _vm._s(_vm.pageNum) + " / " + _vm._s(_vm.pageTotalNum)
                    ),
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "pdf-container" },
                  [
                    _c("pdf", {
                      attrs: { src: _vm.pdfsrc, page: _vm.pageNum },
                      on: {
                        progress: function ($event) {
                          _vm.loadedRatio = $event
                        },
                        "num-pages": function ($event) {
                          _vm.pageTotalNum = $event
                        },
                      },
                    }),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm.type == "wordOrExcel"
            ? _c("div", { staticClass: "word-wrapper" }, [
                _c("iframe", {
                  ref: "iframe",
                  staticClass: "iframe-container",
                  attrs: { name: "word", src: _vm.wordUrl, frameborder: "0" },
                }),
              ])
            : _vm._e(),
          _vm.type == "picture"
            ? _c(
                "div",
                { staticClass: "word-wrapper" },
                [
                  _c("el-image", {
                    staticStyle: { width: "100%" },
                    attrs: { src: _vm.picSrc },
                  }),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }