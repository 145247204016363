<!--
 * @Author: xfj
 * @Date: 2019-09-17 17:56:26
 * @LastEditors: xfj majun
 * @LastEditTime: 2020-03-26 14:06:13
 * @Description: 房号资产剖面图-子组件-房屋查询
 -->
<template>
  <div class="floors-wrapper"
       ref="floors">
    <div class="floor"
         v-for="(floor, floorIndex) in listFloor"
         v-show="currentFloor===floor.floor || currentFloor === 0"
         :key="floorIndex">
      <!-- tooltip  -->
      <div :ref="'tooltip_'+ floorIndex"
           v-show="floor.tipValue"
           class="floortip el-tooltip__popper is-dark"
           style="transform-origin: center bottom; z-index: 5180;">
        <div class="content">
          <p>{{ selectedRoom.name }}</p>
          <p>{{ selectedRoom.buildingArea }}m&sup2;</p>
           <template v-if="viewType === 0">
          <div v-if="selectedRoom.listOrg && selectedRoom.listOrg.length > 0">
            <p v-for="(org, key) in selectedRoom.listOrg"
               :key="key">
              {{ org }}
            </p>
          </div>
          <p  v-if="selectedRoom.listOrg && selectedRoom.listOrg.length === 0">未入驻</p>
          </template>
          <template v-if="viewType === 1">
            {{ isSellMap[selectedRoom.isSell] }}
          </template>
        </div>
        <div x-arrow=""
             class="tip_arrow popper__arrow"
             style="left: 28px;"></div>
      </div>
      <div class="floor-header">
        <div class="floor-header-name"> {{ floor.floor }}层</div>
        <div class="floor-header-status">
          <template v-if="viewType === 0">
            <!--<div class="floor-header-status-info"><span>未租</span> <span>{{ floor.isLease0Number }}</span></div> -->
            <div class="floor-header-status-info"><span>待租</span> <span>{{ floor.forLeaseArea }}m&sup2;</span></div>
          </template>
          <template v-if="viewType === 1">
            <!--<div class="floor-header-status-info"><span>未售</span> <span>{{ floor.isSell0Number }}</span></div>-->
            <div class="floor-header-status-info"><span>待售</span> <span>{{ floor.forSellArea}}m&sup2;</span></div>
          </template>
        </div>
      </div>
      <ul class="floor-content">
        <li
          v-for="(item,index) in floor.roomList"
          :style="{'width':item.percent}"
          :key="index"
          :class="['floor-content-house', getClass(item)]"
          @click.stop="clickRoom(item)">
            <div class="floor-content-house-info">
              <div>
                <template v-if="item.rate >= standard">
                  <span class="floor-content-house-info-name">{{ item.room }}</span>
                  <span class="floor-content-house-info-area">{{ item.buildingArea }}m&sup2;</span>
                </template>
                <template v-if="item.rate < standard && item.rate > 0">
                  ...
                </template>
              </div>
            </div>
            <div class="floor-content-house-group"
                  ref="test"
                  v-on:mouseenter="mouseenter(item,index,floorIndex)"
                  v-on:mouseleave="mouseleave(item,index,floorIndex)">

              <div v-if="item.listOrg.length > 0" style="width:100%;text-align:center;">
                <template v-if="item.rate >= standard">
                  <template v-if="viewType === 0">
                    <div class="floor-content-house-group-title" :style="{'justify-content':item.listOrg.length < 5 ? 'center' : 'flex-start'}">
                      <p v-for="(org,orgIndex) in  item.listOrg"
                            :key="orgIndex">
                        {{ org }}
                      </p>
                    </div>
                  </template>
                  <template v-if="viewType === 1">
                     {{ isSellMap[item.isSell]}}
                  </template>
                </template>
                <template v-if="item.rate < standard">
                  ...
                </template>
              </div>
              <div class="floor-content-house-group"
                    v-if="item.listOrg.length === 0">
                <template v-if="item.rate >= standard">
                  {{ viewType === 0 ? '未入驻' : isSellMap[item.isSell] }}
                </template>
                <template v-if="item.rate < standard">
                  ...
                </template>
              </div>
              <div :class="{'isCover':item.isCover}"></div>
            </div>
        </li>
      </ul>
    </div>
    <transition name="fade">
      <div class="my-room-info" v-show="dialogVisible" ref="orgInfo">
        <div class="room-info">
          <span class="goback" @click="dialogVisible=false"><i class="el-icon-arrow-left" />返回</span>
          <div class="base-info">
            <div class="base-info-header">
              <div>
                <span class="pre-point" style="background-color:#7AC943"></span>
                <span>房屋信息</span>
              </div>
              <v-button type="text" v-show="editHousePermission" @click="editHouse(currentRoom)">编辑</v-button>
            </div>
            <div class="base-info-name">
              <span class="flod">{{ currentRoom.name }}</span>
              <span>{{ viewType === 0 ? isLeasesMap[currentRoom.isLease] : isSellMap[currentRoom.isSell] }}</span>
            </div>
            <div class="base-info-name">
              <span>面积：{{ `${currentRoom.buildingArea}m&sup2;`}}</span>
            </div>
          </div>
          <div class="org-info" v-for="(orgInfo,orgIndex) in orgInfos" :key="orgIndex">
              <div class="org-info-base">
                  <div class="org-info-base-header">
                    <span>企业信息</span>
                    <div>
                      <v-button type="text" class="redBtn" v-if="orgMovePermission" @click="orgMove(orgInfo.orgId)">搬离</v-button>
                      <v-button type="text" v-if="orgMgtPermission" @click="orgMgt(orgInfo.orgId)">编辑</v-button>
                    </div>
                  </div>
                  <div class="org-info-base-name flod">{{ orgInfo.orgName }}</div>
                  <div v-if="orgInfo.contactName">
                      联系人：{{ `${orgInfo.contactName}` }}
                  </div>
              </div>
              <template v-if="orgInfo.contractInfos.length > 0">
                <div class="org-info-contact" v-for="(contactInfo, contactIndex) in orgInfo.contractInfos" :key="contactIndex">
                    <div class="org-info-contact-header">
                      <span>{{ contactInfo.contractName }}</span>
                      <div style="flex:0 0 138px">
                        <v-button type="text" class="redBtn" v-show="contactPermission && contactPermission.invalid" @click="voidContact(contactInfo.contractId)">作废</v-button>
                        <v-button type="text" v-show="contactPermission && contactPermission.renew" @click="renewContact(contactInfo.contractId)">续租</v-button>
                        <v-button type="text" v-show="contactPermission && contactPermission.view" @click="viewContact(contactInfo.contractId)">查看</v-button>
                      </div>
                    </div>
                    <div class="org-info-contact-time">
                      <div class="org-info-contact-time-left">
                        <span class="flod"> {{ `${contactInfo.startDate}起${contactInfo.endDate}止` }}</span>
                        <span v-if="contactInfo.fee">{{ `费用: ${contactInfo.fee}` }}</span>
                      </div>
                      <div class="org-info-contact-expired" v-if="contactInfo.remainDays < 180"></div>
                    </div>
                    <v-button type="text" style="padding-left:0px;" v-if="contactInfo.arrearage && chargePermission" @click="chargeBillMgt(orgInfo.orgId)" >查看欠费账单</v-button>
                </div>
              </template>
              <template v-if="orgInfo.contractInfos.length === 0 && contactPermission && contactPermission.add === 1">
                <div class="org-info-contact">
                    <div class="org-info-contact-header">
                      <span>合同信息</span>
                      <div>
                        <v-button type="text" v-show="contactPermission && contactPermission.add === 1" @click="createContact">创建合同</v-button>
                      </div>
                    </div>
                    <div class="org-info-contact-nodata">
                      <img :src="noContactImg" width="17">
                      <span style="padding-top:6px">暂无合同信息</span>
                    </div>
                </div>
              </template>
          </div>
          <div class="org-info"  v-if="orgInfos.length === 0 && contactPermission && contactPermission.add === 1">
            <div class="org-info-contact noOrg">
                <div class="org-info-contact-header">
                  <span>合同信息</span>
                  <div>
                    <v-button type="text" v-show="contactPermission && contactPermission.add === 1" @click="createContact">创建合同</v-button>
                  </div>
                </div>
                <div class="org-info-contact-nodata">
                  <img :src="noContactImg" width="17">
                  <span style="padding-top:6px">暂无合同信息</span>
                </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <template v-if="listFloor.length === 0">
      <div class="empty-wrapper">
        <img :src="emptyImgSrc" />
        <p>请先录入房号…</p>
      </div>
    </template>
  </div>
</template>

<script>
import { Popover, Tooltip } from 'element-ui'
import { isSellMap, isLeasesMap, noContactImg } from './map'
import { getContractInfoByHouseIdURL, updateStatusHanldeURL } from './api'
import emptyImgSrc from './img/empty.png'
export default {
  name: 'floors',
  components: {
    [Popover.name]: Popover,
    [Tooltip.name]: Tooltip
  },
  props: {
    listFloor: {
      type: Array,
      default: () => {
        return []
      }
    },
    viewType: {
      type: Number,
      default: 0
    },
    currentFloor: {
      type: Number,
      default: 0
    }
  },
  computed: {
    // 编辑房号的权限
    editHousePermission () {
      return this.$store.state.menuStore.menuPermission['communityRoomAction!list.action2'] && this.$store.state.menuStore.menuPermission['communityRoomAction!list.action2'].update === 1
    },
    // 收费管理的权限
    chargePermission () {
      return this.$store.state.menuStore.menuPermission.chargingBillManage1 && this.$store.state.menuStore.menuPermission.chargingBillManage1.view === 1
    },
    // 组织管理
    orgMgtPermission () {
      return this.$store.state.menuStore.menuPermission.orgManage1 && this.$store.state.menuStore.menuPermission.orgManage1.update === 1
    },
    // 组织搬离
    orgMovePermission () {
      return this.$store.state.menuStore.menuPermission.orgMove1 && this.$store.state.menuStore.menuPermission.orgMove1.view === 1
    },
    // 合同
    contactPermission: function () {
      return this.$store.state.menuStore.menuPermission.orgContract
    }
  },
  mounted () {
    let _this_ = this
    let clientWidth = _this_.$refs.floors.clientWidth
    _this_.standard = 60 / clientWidth
    window.onresize = () => {
      return (() => {
        let width = _this_.$refs.floors.clientWidth
        _this_.standard = 60 / width
      })()
    }
  },
  created () {
    this.$setBreadcrumb('房屋查询')
  },
  data () {
    return {
      emptyImgSrc,
      tipIndex: 0,
      standard: 0,
      tipValue: false,
      selectedRoom: {},
      dialogVisible: false,
      currentRoom: {},
      isSellMap,
      isLeasesMap,
      noContactImg,
      orgInfos: []
    }
  },
  methods: {
    // 当鼠标移动时触发
    mouseenter (item, index, floorIndex) {
      this.tipIndex = floorIndex
      this.selectedRoom = item
      this.listFloor[floorIndex].tipValue = true
      this.tipValue = true
    },
    mouseleave (item, index, floorIndex) {
      this.listFloor[floorIndex].tipValue = false
      this.tipValue = false
    },
    //
    popShow (index) {
      this.listFloor[index].tipValue = false
    },
    // 获取房号下的 组织 合同信息
    getContractInfoByHouseId () {
      let params = {
        houseId: this.currentRoom.roomId
      }
      this.orgInfos = []
      this.$axios.get(getContractInfoByHouseIdURL, { params }).then(res => {
        if (res.status === 100) {
          if (res.data) {
            this.orgInfos = res.data.orgInfos
          }
        }
      })
    },
    // 跳转编辑房号
    editHouse (item) {
      this.$router.push({
        name: 'validHouseForm',
        query: {
          id: item.roomId
        }
      })
    },
    // 跳转组织管理详情页
    orgMgt (orgId) {
      this.$router.push({
        name: 'orgManageForm',
        query: {
          id: orgId,
          edit: true
        }
      })
    },
    // 组织搬离
    orgMove (orgId) {
      this.$router.push({
        name: 'orgMove1',
        query: {
          houseId: this.currentRoom.roomId,
          orgId: orgId
        }
      })
    },
    // 创建合同
    createContact () {
      this.$router.push({
        name: 'contractManageForm'
      })
    },
    // 查看合同
    viewContact (contactId) {
      this.$router.push({
        name: 'contractManageDetail',
        query: {
          id: contactId
        }
      })
    },
    // 续租合同
    renewContact (contactId) {
      this.$router.push({
        name: 'contractManageForm',
        query: {
          id: contactId,
          renew: 'renew'
        }
      })
    },
    // 作废合同
    async voidContact (contactId) {
      let msg = '作废合同后，合同状态将变为作废，需手动处理该合同相关的收费规则、账单、房号，是否确认作废？'
      let title = '确认作废'
      let isOk = await this.$confirm(msg, { title: title })
      isOk && this.requestForUpdateStatus(contactId)
    },
    // 作废
    requestForUpdateStatus (contactId) {
      let _this = this
      this.$axios({
        method: 'PUT',
        url: updateStatusHanldeURL,
        headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' },
        data: this.$qs.stringify({
          id: contactId
        })
      }).then(res => {
        if (res.status === 100) {
          // _this.$refs.list.searchData()
          _this.$message.success('操作成功')
        }
      })
    },
    // 跳转收费账单
    chargeBillMgt (orgId) {
      this.$router.push({
        name: 'chargingBillManage1',
        query: {
          houseIdList: this.currentRoom.roomId,
          orgId: orgId,
          checkStatus: '0',
          overdue: 1
        }
      })
    },
    // selectRoom
    clickRoom (room) {
      this.currentRoom = room
      this.dialogVisible = true
      if (room.roomId) {
        this.getContractInfoByHouseId()
      }
    },
    // 根据状态获取class
    getClass (room) {
      let className = ''
      if (this.viewType === 0) {
        // item.listOrg.length > 0 && item.expired === 0 }, {'noOrg': item.listOrg.length === 0
        if (room.listOrg.length > 0) {
          className = room.expired === 0 ? 'Org' : 'expired'
        } else {
          className = 'noOrg'
        }
      } else {
        // 未出售 出售中
        if (room.isSell === 0 || room.isSell === 2) {
          className = 'noOrg'
        } else if (room.isSell === 1 || room.isSell === 3 || room.canSell === 0) {
          className = 'Org'
        }
      }
      return className
    }
  },
  watch: {
    listFloor: {
      handler () {
        let _this_ = this
        this.$nextTick(function () {
          /* 现在数据已经渲染完毕 */
          let nodeList = document.querySelectorAll('.floor-content-house-group')
          _this_.$nextTick(() => {
            nodeList.forEach(node => {
              node.onmousemove = function (ev) {
                var oEvent = ev || event
                // tip
                let oDiv = _this_.$refs['tooltip_' + _this_.tipIndex][0]
                let arrow = oDiv.querySelector('.tip_arrow')
                let tipWidth = oDiv.clientWidth
                let tipHeight = oDiv.clientHeight
                let tipHalfWidth = (tipWidth - 6) / 2
                arrow.style.left = tipHalfWidth + 'px'
                let offsetRight = document.body.offsetWidth - oEvent.x
                if (offsetRight - tipHalfWidth - 5 > 0) {
                  oDiv.style.left = oEvent.x - tipHalfWidth + 'px'
                } else {
                  oDiv.style.left = oEvent.x - tipWidth + 'px'
                }
                oDiv.style.top = oEvent.y - 20 - tipHeight + 'px'
              }
            })
          })
        })
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss" scoped>
.floors-wrapper {
  width: 100%;
  margin-top: 16px;
  color: #333;
  .floor {
    position: relative;
    margin-bottom: 16px;
    margin-right:12px;
    &-header {
      height: 100px;
      width:84px;
      margin-right:16px;
      position:absolute;
      float:left;
      &-name {
        height:25px;
        font-size:18px;
        line-height:25px;
        font-weight:700;
        color:rgba(51,51,51,1);
      }
      &-status {
        font-size:12px;
        margin-top:40px;
       &-info {
         display:flex;
         justify-content:space-between;
         margin-top:10px;
          span {
            display:block;
          }
       }
      }
    }
    &-content {
      margin-left:100px;
      display: flex;
      height: 100px;
      border: 1px solid #c5c5c5;
      &-house {
        border-right: 1px solid #c5c5c5;
        position: relative;
        height: 100px;
        &-info {
          height: 25px;
          padding-top:10px;
          text-align: center;
          &-name {
            color: #1b8cf2;
            cursor: pointer;
            overflow: hidden;
            height: 20px;
            width: 100%;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          &-area {
            font-size: 12px;
            margin:0 8px;
          }
          &-icon {
            display: inline-block;
            width: 20px;
            height: 20px;
            background: url('./img/deadLine.png');
            background-size: 100%;
            vertical-align: middle;
          }
        }
        &-group {
          height:65px;
          display: flex;
          align-items: center;
          justify-content: center;
          &-span {
            width: 5px;
            height: 5px;
            display: block;
            background-color: #1b8cf2;
          }
          &-title {
            padding: 0 8px;
            display: flex;
            flex-direction: column;
            height: 45px;
            overflow: hidden;
            pointer-events: none;
            width:calc(100% - 16px);
            p {
              flex:0 0 18px;
              height: 18px;
              margin:0;
              padding: 0;
              overflow: hidden;
              text-overflow:ellipsis;/* 超出部分显示省略号 */
              white-space: nowrap;
            }
          }
        }
        &:hover {
          transform: scale(1.03);
          transition: transform 0.2s;
          transition-timing-function: ease;
          box-shadow: 0px 5px 5px #aaa;
          cursor: pointer;
          z-index:22;
        }
      }

      .Org {
        background-color: #E0E0E0;
      }
      .noOrg {
        width:100%;
        background-color: #48A8FF;
        color: #fff;
        .floor-content-house-info-name {
          color:#fff;
        }
      }
      // .noOrg:hover {
      //   background-color:#FFD99C;
      // }
      .expired {
        background-color: #FFD99C;
      }
      &-house:last-child {
        border-right: none;
      }
      .isCover {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.6);
        z-index: 19;
      }
    }
  }
  .my-room-info {
    position: fixed;
    top: 350px;
    right: 24px;
    bottom: 44px;
    background: #fff;
    width: 360px;
    box-shadow:-6px 0px 8px 0px rgba(0,0,0,0.2);
    border-radius:8px 0px 0px 8px;
    z-index:88;
    padding:10px;

    .room-info {
      height:100%;
      overflow:auto;
      .goback {
        height:25px;
        border-radius:13px;
        background:rgba(27,140,242,1);
        color:#fff;
        padding: 0px 12px 0px 6px;
        font-size:12px;
        cursor:pointer;
        display: inline-block;
        line-height: 25px;
      }
      .base-info {
        margin-top:10px;
        margin-right:20px;
        &-header {
          display:flex;
          justify-content:space-between;
        }
        &-name {
          display:flex;
          justify-content:space-between;
          align-items:center;
        }
      }
      .org-info {
        background:#F2F3F7;
        margin-top:16px;
        padding:0 14px;
        border-radius:4px;
        &-base,&-contact {
          padding:10px 0;
          &-header {
            display:flex;
            justify-content:space-between;
          }
        }
        &-contact {
          border-top:1px solid #C5C5C5;
          &-time {
            display:flex;
            justify-content:space-between;
            &-left {
              display:flex;
              flex-direction:column;
            }
          }
          &-expired {
            width:52px;
            height:52px;
            background:url('./img/expired.png');
            background-size:100% 100%;
            margin-top:10px;
          }
          &-nodata {
            height:74px;
            background:rgba(224,224,224,1);
            border-radius:4px;
            color:#aaa;
            margin-top:10px;
            display:flex;
            justify-content:center;
            align-items:center;
            flex-direction: column;
            font-size: 12px;
          }
        }
        .noOrg {
          border:none;
        }
      }
      .flod {
        font-weight:700;
        font-size:16px;
        margin:10px 0 8px 0;
      }
    }

    .v-button-container {
      padding-left:16px;
    }
    .redBtn{
      ::v-deep {
        .el-button--text {
          color:#F44B1E;
        }
      }
    }
    .pre-point {
      display: inline-block;
      border-radius: 50%;
      background-color: #7ac943;
      height: 8px;
      width: 8px;
      margin-right: 5px;
    }
  }
  .fade-enter-active {
    animation: my-dialog-fade-in 0.5s;
  }
  .fade-leave-active {
    animation: my-dialog-fade-out 0.5s;
  }
  .fade-enter, .fade-leave-to {
    opacity: 0;
    transform: translateY(30px);
  }
  @keyframes my-dialog-fade-in {
    0% {
      transform: translate3d(100%, 0, 0);
      opacity: 0;
    }
    100% {
      transform: translate3d(0, 0, 0);
      opacity: 1;
    }
  }

  @keyframes my-dialog-fade-out {
    0% {
      transform: translate3d(0, 0, 0);
      opacity: 1;
    }
    100% {
      transform: translate3d(100%, 0, 0);
      opacity: 0;
    }
  }
  .empty-wrapper {
      width: 100%;
      height: 100%;
      min-height: 600px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: #333333;
  }
}

</style>
<style lang="scss">
.floors-wrapper .floortip {
  pointer-events: none;
  position:fixed;
  top: 0;
  left: 0;
}
.floors-wrapper .floortip .popper__arrow {
  bottom: -6px;
  /* margin-left: -6px; */
  border-top-color: #000;
  border-bottom-width: 0;
}
.popover-ul {
  width: 84px;
  padding: 0;
  li {
    line-height: 20px;
    padding: 7px 0;
    border-bottom: 1px solid #e0e0e0;
    cursor: pointer;
  }
  li:last-child {
    border-bottom: none;
  }
}
.floor-popover {
  min-width: 84px;
  padding: 0 8px;
}
.houseProfileList-wrapper .el-scrollbar__wrap{
  overflow-x: hidden;
}
</style>
