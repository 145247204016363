 <!--
 * @Description: 楼栋查询剖面图-子组件-楼栋查询
 * @Author: majun
 * @Date: 2020-10-09 15:35:25
 * @LastEditors: majun
 * @LastEditTime:
 -->
<template>
  <div class="builds-wrapper" ref="builds">
    <div class="build" v-for="(build, buildIndex) in listBuild" :key="buildIndex" @click="toInfo(build)">
      <div class="top-height" >
        <div class="build-contain" >
          <template>
            <span class="build-span" >{{ build.buildingNumber }}</span>
          </template>
        </div>
      </div>
      <div>
        <div class="unit-contain" >
          <template>
            <span class="font-16 font-b" :title="`单元数:${build.unitNum}`">单元数:{{ build.unitNum }}</span>
          </template>

        </div>

        <div class="floor-contain" >
          <template>
            <span class="font-16 font-b" :title="`楼层:${build.floorNum}`">楼层:{{ build.floorNum }}</span>
          </template>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import buildImgSrc from './img/open_community.png'
import { getBuildTreeURL } from './api'
export default {
  name: 'builds',
  components: {},
  props: {
    listBuild: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data () {
    return {
      standard: 0,
      buildImgSrc
    }
  },
  computed: {
    defaultExpandedKeys () {
      return this.$store.state.houseProfileStore.treeExpandedKeys
      // return []
    }
  },
  created () {
    this.$setBreadcrumb('楼幢查询')
  },
  mounted () {
    let _this_ = this
    let clientWidth = _this_.$refs.builds.clientWidth
    _this_.standard = 60 / clientWidth
    window.onresize = () => {
      return (() => {
        let width = _this_.$refs.builds.clientWidth
        _this_.standard = 60 / width
      })()
    }
  },
  methods: {
    async toInfo (item) {
      let that = this
      let sParams = {
        communityId: item.communityId,
        buildingNumber: item.buildingNumber,
        blockName: item.blockName
      }
      await that.$axios.get(getBuildTreeURL, { params: sParams }).then(res => {
        if (res.status === 100) {
          if (res.data) {
            if (res.data.buildTrees && Object.keys(res.data.buildTrees).length > 0) {
              that.$store.commit('houseProfileStore/setTreeExpanedKeys', [])
              res.data.buildTrees.forEach(tree => {
                if (tree) {
                  this.defaultExpandedKeys.push(tree)
                }
              })
              that.$store.commit('houseProfileStore/setTreeExpanedKeys', this.defaultExpandedKeys)
            }
            if (res.data.buildingNodeId) {
              this.$emit('sendNodeId', res.data.buildingNodeId)
              that.$store.commit('houseProfileStore/setDefaultCurrentKey', res.data.buildingNodeId)
            }
            that.$store.commit('houseProfileStore/setSelectedNode', sParams)
            that.$parent.$parent.getBuildingInfo(sParams)
          }
        }
      })
      // that.$router.push({
      //   name: 'houseProfileList!house2',
      //   query: sParams
      // // })
    }
  },
  watch: {
    listBuild: {
      handler () {
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss" scoped>
.builds-wrapper {
  width: 100%;
  margin-top: 15px;
  margin-left: 7px;
  color: #333;
  display: flex root;
  .build {
    position: relative;
    margin-bottom: 20px;
    margin-right: 16px;
    width: 18%;
    height: 120px;
    float: left;
    border: 1px solid #e7e7e7;
    border-radius: 8px;
    background-color: #fafbfd;
    cursor: pointer;
    // box-shadow: 1px 1px 7px #e5e5e5;
    &:hover{
      position: relative;
      margin-bottom: 20px;
      margin-right: 16px;
      width: 18%;
      height: 120px;
      float: left;
      border: 1px solid #ffffff;
      border-radius: 8px;
      // background-color: #FFFFFF;
      cursor: pointer;
      box-shadow: 1px 1px 7px #e5e5e5;
    }
    .top-height {
      height:74px;
    }
    .com-block-contain {
      height:100%;
      width:70%;
      float:left;
    }
    .com-contain {
      margin-left: 50px;
      margin-top:20px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .block-contain {
      margin-left: 50px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .com-span {
       font-size:20px;
       font-weight:600;
    }
    .build-contain {
      height:100%;
      float:left;
      line-height:74px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .build-span {
      font-size:22px;
      font-weight:500;
      color: #1D5384;
      margin-left: 12px;
      line-height: 30px;
    }
    .unit-contain {
      box-sizing: border-box;
      float:left;
      width: 50%;
      // margin-left: 12px;
      padding-left: 12px;
      line-height:22px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .font-12 {
      font-size: 12px;
    }
    .font-16 {
      font-size:16px;
    }
    .font-b {
      font-weight:400;
    }
    .separator {
      width:1px;
      height:30px;
      background-color:#e9e9e9;
      float:left;
      margin-top:10px;
    }
    .floor-contain {
      box-sizing: border-box;
      float: right;
      text-align: right;
      // margin-right: 28px;
      line-height:22px;
      width: 50%;
      padding-right: 28px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}
</style>
